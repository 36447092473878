.authContainer {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
}

.authContainer h1 {
  font-size: 2rem;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 32px;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.inputGroup label {
  font-weight: 500;
  color: #4a4a4a;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.inputWrapper input {
  width: 100%;
  padding: 12px 16px 12px 40px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.inputWrapper input:focus {
  border-color: #cd7f32;
  outline: none;
}

.inputWrapper i {
  position: absolute;
  left: 12px;
  color: #666;
}

.submitButton {
  background-color: #cd7f32;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submitButton:hover {
  background-color: #b26b2a;
}

.googleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.googleButton:hover {
  background-color: #f5f5f5;
}

.googleButton img {
  width: 24px;
  height: 24px;
}

.divider {
  text-align: center;
  margin: 20px 0;
  position: relative;
}

.divider::before,
.divider::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background-color: #e0e0e0;
}

.divider::before {
  left: 0;
}

.divider::after {
  right: 0;
}

.error {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: -8px;
}

.switchAuth {
  text-align: center;
  margin-top: 20px;
}

.switchAuth a {
  color: #cd7f32;
  text-decoration: none;
  font-weight: 500;
}

.switchAuth a:hover {
  text-decoration: underline;
} 