.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #cd7f32;
}

.links {
  display: flex;
  gap: 2rem;
}

.links a {
  text-decoration: none;
  color: #4a4a4a;
  font-weight: 500;
  transition: color 0.2s;
}

.links a:hover {
  color: #cd7f32;
}

.auth {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.auth span {
  color: #4a4a4a;
}

.auth button {
  padding: 0.5rem 1rem;
  background-color: #cd7f32;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.auth button:hover {
  background-color: #b26b2a;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 1rem;
  }

  .links {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
  }

  .auth {
    flex-direction: column;
    align-items: center;
  }
} 