@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root, html, body, #root {
    @apply min-h-screen;
  }

  body {
    @apply bg-amber-50 dark:bg-blue-900;
  }

  #root {
    @apply bg-amber-50 dark:bg-blue-900;
  }

  /* Ensure all containers inherit the dark mode background */
  .dark body > div,
  .dark #root > div,
  .dark main,
  .dark main > div {
    @apply bg-blue-900;
  }
}

/* Add dark mode background color for main content areas */
.dark .bg-content {
  @apply bg-blue-900;
}

/* Ensure consistent dark mode styling */
.dark .bg-section {
  @apply bg-blue-800;
}

/* Override any default white backgrounds in dark mode */
.dark * {
  background-color: inherit;
}

/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(252, 211, 77, 0.2);
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(252, 211, 77, 0.8);
  border-radius: 20px;
  border: 2px solid rgba(252, 211, 77, 0.2);
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(252, 211, 77, 1);
}

/* For Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(252, 211, 77, 0.8) rgba(252, 211, 77, 0.2);
}

/* Main content scrollbar */
body::-webkit-scrollbar {
  width: 14px;
}

body::-webkit-scrollbar-track {
  background: rgba(253, 224, 71, 0.2); /* Lighter yellow background */
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(253, 224, 71, 0.9); /* More vibrant yellow thumb */
  border-radius: 20px;
  border: 3px solid rgba(253, 224, 71, 0.2);
}

body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(253, 224, 71, 1); /* Full yellow on hover */
}

/* For Firefox */
body {
  scrollbar-width: thin;
  scrollbar-color: rgba(253, 224, 71, 0.9) rgba(253, 224, 71, 0.2);
}

/* Glow effect for headers */
@keyframes glow {
  0%, 100% { text-shadow: 0 0 5px rgba(253, 224, 71, 0.5); }
  50% { text-shadow: 0 0 20px rgba(253, 224, 71, 0.8); }
}

.hover\:glow-yellow-300:hover {
  animation: glow 2s ease-in-out infinite;
}

@keyframes glow-amber {
  0%, 100% { 
    text-shadow: 0 0 5px rgba(253, 224, 71, 0.5), 0 0 10px rgba(253, 224, 71, 0.3);
    opacity: 1;
  }
  50% { 
    text-shadow: 0 0 20px rgba(253, 224, 71, 0.8), 0 0 30px rgba(253, 224, 71, 0.5);
    opacity: 0.7;
  }
}

.hover\:glow-amber-700:hover {
  animation: glow-amber 2s ease-in-out infinite;
}

/* Additional styles for improved UI */
.sidebar-hover-effect {
  transition: all 0.3s ease;
}

.sidebar-hover-effect:hover {
  transform: translateX(5px);
}

.custom-shadow {
  box-shadow: 0 4px 6px -1px rgba(251, 191, 36, 0.1), 0 2px 4px -1px rgba(251, 191, 36, 0.06);
}

.custom-transition {
  transition: all 0.3s ease;
}

@layer utilities {
  .drop-shadow-glow {
    filter: drop-shadow(0 0 2px rgba(250, 204, 21, 0.7));
  }
  
  .hover\:text-yellow-300:hover {
    color: #fde047; /* This is the Tailwind yellow-300 color */
  }
  
  @keyframes flicker {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }

  .animate-flicker {
    animation: flicker 2s ease-in-out infinite;
  }
  
  @keyframes glow-constant {
    0%, 100% { 
      text-shadow: 0 0 7px rgba(253, 224, 71, 0.7), 0 0 10px rgba(253, 224, 71, 0.5);
    }
    50% { 
      text-shadow: 0 0 15px rgba(253, 224, 71, 0.9), 0 0 20px rgba(253, 224, 71, 0.7);
    }
  }

  .dark .animate-glow-constant {
    animation: glow-constant 2s ease-in-out infinite;
  }

  .safe-top {
    padding-top: env(safe-area-inset-top);
  }
  
  .safe-bottom {
    padding-bottom: env(safe-area-inset-bottom);
  }

  .mobile-touch-target {
    min-height: 44px; /* iOS minimum touch target size */
  }

  @media (max-width: 640px) {
    .scrolling-touch {
      -webkit-overflow-scrolling: touch;
    }
    
    .tap-highlight-transparent {
      -webkit-tap-highlight-color: transparent;
    }
  }

  .dropdown-transition {
    transition: max-height 0.3s ease-in-out, opacity 0.2s ease-in-out;
  }
  
  .dropdown-enter {
    max-height: 0;
    opacity: 0;
  }
  
  .dropdown-enter-active {
    max-height: 500px;
    opacity: 1;
  }
  
  .dropdown-exit {
    max-height: 500px;
    opacity: 1;
  }
  
  .dropdown-exit-active {
    max-height: 0;
    opacity: 0;
  }

  /* Add these mobile menu transition utilities */
  .mobile-menu-transition {
    transition-property: transform, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
  }

  .mobile-menu-enter {
    transform: translateX(-100%);
  }

  .mobile-menu-enter-active {
    transform: translateX(0);
  }

  .mobile-menu-exit {
    transform: translateX(0);
  }

  .mobile-menu-exit-active {
    transform: translateX(-100%);
  }

  /* Prevent body scroll when mobile menu is open */
  .overflow-hidden {
    overflow: hidden;
    position: fixed;
    width: 100%;
  }
}

/* Improve mobile scrolling */
.custom-scrollbar {
  @apply scrolling-touch;
}

/* Add smooth momentum scrolling on iOS */
@supports (-webkit-overflow-scrolling: touch) {
  .custom-scrollbar {
    -webkit-overflow-scrolling: touch;
  }
}

@layer components {
  .load-shedding-container {
    @apply max-w-6xl mx-auto px-4 py-8;
  }

  .load-shedding-title {
    @apply text-4xl font-bold mb-8 text-amber-800 dark:text-yellow-300 dark:animate-glow-constant;
  }

  .info-card {
    @apply bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6;
  }

  .info-card-title {
    @apply text-2xl font-semibold mb-4 text-amber-700 dark:text-yellow-400;
  }

  .status-item {
    @apply flex items-center justify-between;
  }

  .status-label {
    @apply text-lg text-gray-600 dark:text-gray-300;
  }

  .status-value {
    @apply text-3xl font-bold text-amber-600 dark:text-yellow-400;
  }

  .search-input {
    @apply flex-grow p-2 border border-amber-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-amber-500 dark:bg-gray-700 dark:border-amber-600 dark:text-white;
  }

  .search-button {
    @apply bg-amber-500 text-white p-2 rounded-r-md hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-amber-500 dark:bg-amber-600 dark:hover:bg-amber-700;
  }

  .search-result-item {
    @apply bg-amber-50 dark:bg-gray-700 p-3 rounded-md;
  }

  .power-saving-tip {
    @apply flex items-start bg-amber-50 dark:bg-gray-700 p-3 rounded-md;
  }

  .power-saving-icon {
    @apply h-6 w-6 text-amber-500 dark:text-yellow-400 mr-2 flex-shrink-0;
  }

  .power-saving-text {
    @apply text-amber-900 dark:text-gray-300;
  }
}